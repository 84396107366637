import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import Storage from 'vue-ls'
import '@/permission'
import 'amfe-flexible/index.js'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// import './mock'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

/*import Vconsole from 'vconsole'
let vConsole = new Vconsole()
Vue.use(vConsole)*/

const options = {
  namespace: 'vuejs__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local' // 存储名称: session, local, memory
}
Vue.use(Vant)
Vue.use(Storage, options)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
