import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'register',
    meta: { title: '登录' },
    component: () => import('../views/register')
  },
  {
    path: '/',
    name: 'index',
    meta: { title: '康域AI' },
    component: () => import('../views/index')
  },
  {
    path: '/user',
    name: 'user',
    meta: { title: '我的基本信息' },
    component: () => import('../views/index/user')
  },
  {
    path: '/order',
    name: 'order',
    meta: { title: '我的订单' },
    component: () => import('../views/order')
  },
  {
    path: '/shop',
    meta: { title: '康域门店' },
    name: 'shop',
    component: () => import('../views/shop')
  },
  {
    path: '/pay',
    meta: { title: '康域AI熏蒸舱' },
    name: 'pay',
    component: () => import('../views/pay')
  },
  {
    path: '/report/:tongueCaseId',
    meta: { title: '健康报告' },
    name: 'report',
    component: () => import('../views/report')
  },
  {
    path: '/forget',
    name: 'forget',
    meta: { title: '忘记密码' },
    component: () => import('../views/register/forgetPwd')
  },
  {
    path: '/reportList',
    name: 'reportList',
    meta: { title: '报告' },
    component: () => import('../views/report/list')
  },
  {
    path: '/couponList',
    name: 'couponList',
    meta: { title: '我的优惠券' },
    component: () => import('../views/coupon/index')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
